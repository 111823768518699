@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-style.scss';

.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  position: relative;
  z-index: 2;
}

.textBox {
  display: flex;
  flex-direction: column;
  gap: size-rem(28px);
  align-items: center;

  @include vp--740 {
    gap: size-rem(28px, true);
  }
}

.title {
  @include p-85;
  color: $clr-dark-black;

  @include vp--740 {
    font-size: size-rem(60px, true);
    line-height: 95%;
    text-align: center;
    font-weight: 350;
  }
}

.description {
  @include p-18;
  color: $clr-dark-black;

  @include vp--740 {
    font-size: size-rem(16px, true);
    line-height: 120%;
    font-weight: 350;
    text-align: center;
  }
}

.browsers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: size-rem(15px);
  margin-top: size-rem(35px);

  @include vp--740 {
    gap: size-rem(15px, true);
    margin-top: size-rem(45px, true);
  }

  &__icon {
    width: size-rem(50px);
    height: size-rem(50px);
    border-radius: 50%;
    border: 1px solid $clr-grey;
    display: flex;
    align-items: center;
    justify-content: center;

    @include vp--740 {
      width: size-rem(50px, true);
      height: size-rem(50px, true);
    }
  }

  & svg {
    width: size-rem(20px);
    height: size-rem(20px);

    @include vp--740 {
      width: size-rem(20px, true);
      height: size-rem(20px, true);
    }
  }
}

.svgLine {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 95%;

  @include vp--740 {
    width: 100%;
    top: size-rem(200px, true);
  }
}
